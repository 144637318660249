import { UTMDataType } from 'Marketing/types';

/**
 * Helper retrieving cookie values
 */
export function getCookie(cookieName: string) {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  // TODO:: investigate why this is a 'null' string
  return 'null';
}

/**
 * Adapter to retrieve and organize campaign cookies
 */
export function getUTMData() {
  const campaignFirstCookie = getCookie('campaign_first');
  const campaignLatestCookie = getCookie('campaign_latest');
  const lastPage = document.referrer || '';

  const first = JSON.parse(campaignFirstCookie) || {};
  const latest = JSON.parse(campaignLatestCookie) || {};

  const UTMData: UTMDataType = {
    ...(first.landing_page && { utm_1st_URL: first.landing_page }),
    ...(first.utm_campaign && { utm_1st_campaign: first.utm_campaign }),
    ...(first.utm_content && { utm_1st_content: first.utm_content }),
    ...(first.utm_medium && { utm_1st_medium: first.utm_medium }),
    ...(first.utm_referrer && { utm_1st_referrer: first.utm_referrer }),
    ...(first.utm_source && { utm_1st_source: first.utm_source }),
    ...(first.utm_term && { utm_1st_term: first.utm_term }),
    ...(first.utm_campaignid && { utm_1st_campaignid: first.utm_campaignid }),
    ...(first.click_date && { utm_1st_click_date: first.click_date }),
    ...(first.gclid && { utm_1st_gclid: first.gclid }),
    ...(latest.utm_campaign && { utm_campaign: latest.utm_campaign }),
    ...(latest.utm_content && { utm_content: latest.utm_content }),
    ...(latest.utm_medium && { utm_medium: latest.utm_medium }),
    ...(latest.utm_referrer && { utm_referrer: latest.utm_referrer }),
    ...(latest.utm_source && { utm_source: latest.utm_source }),
    ...(latest.utm_term && { utm_term: latest.utm_term }),
    ...(latest.utm_campaignid && { utm_campaignid: latest.utm_campaignid }),
    ...(latest.click_date && { utm_click_date: latest.click_date }),
    ...(latest.utm_gclid && { utm_gclid: latest.utm_gclid }),
    utm_last_page: lastPage,
  };

  return UTMData;
}
