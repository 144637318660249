import React from 'react';

export type StepNumber = number;
export type SignupData = {
  country?: string;

  email?: string;
  // email or from sso
  login?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  // amazon sso
  postCode?: string;
  amazonOauthUid?: string;
  amazonOauthAccessToken?: string;
  sso?: boolean;

  companyName?: string;
  region?: string;
  city?: string;
  addressLine1?: string;
  phone?: string;
  marketingConsent?: boolean;
  termsAndConditions?: boolean;
  // sp api
  mwsAuthToken?: string;
  spapiOauthCode?: string;
  sellingPartnerId?: string;

  timezone?: string;

  monthlyOrderVolume?: string;
};

export type LoginData = {
  email: string;
  password: string;
};

export type State = {
  signupData: SignupData;
  login: {
    isVerificationRequired: boolean;
  };
};

export enum ActionType {
  SET_ACTIVE_STEP = 'SET_ACTIVE_STEP',
  SET_FORM_DATA = 'SET_FORM_DATA',
  SET_COMPLETED_STEP = 'SET_COMPLETED_STEP',
  RESET_SIGNUP_DATA = 'RESET_SIGNUP_DATA',
  SET_USER_NEEDS_VERIFICATION = 'USER_NEEDS_VERIFICATION',
}

export type Action =
  | {
      type: ActionType.SET_ACTIVE_STEP;
      activeStep: StepNumber;
    }
  | {
      type: ActionType.SET_FORM_DATA;
      signupData: SignupData;
    }
  | {
      type: ActionType.SET_COMPLETED_STEP;
      completedStep: StepNumber;
    }
  | {
      type: ActionType.RESET_SIGNUP_DATA;
    }
  | {
      type: ActionType.SET_USER_NEEDS_VERIFICATION;
      isVerificationRequired: boolean;
    };

export interface ContextProviderProps {
  children: React.ReactNode;
}
