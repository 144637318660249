import { ROWFormData } from 'components/Signup/ROWWaitingListForm';
import { addUTMData, identifySegmentEvent, trackSegmentEvent } from './utils/helpers';
import { Event, WaitingList } from './types';

interface TrackingData extends ROWFormData {
  firstName: string | undefined;
  lastName: string | undefined;
}

export const ROWwaitingListTracking = (formData: TrackingData) => {
  const { firstName, lastName, email, country, marketingConsent } = formData;

  const baseData = {
    firstName,
    lastName,
    email,
    country,
    marketingConsent: marketingConsent ? new Date().toLocaleString('en-GB') : false,
    signup_waiting_list: WaitingList.ROW,
    company: {
      name: email,
    },
  };

  const dataWithUTM = addUTMData(baseData);

  identifySegmentEvent(dataWithUTM);
  trackSegmentEvent(Event.ROW_WAITING_LIST_SUBSCRIPTION, { firstName, lastName, email, country });
};
