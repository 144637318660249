import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import { useWindowSize } from 'utils/useWindowSize';
import ROWWaitingList from 'components/Signup/ROWWaitingList';
import Homepage from './Homepage';
import CompanyDetails from './CompanyDetails';
import MobileWaitingList from '../Mobile/MobileWaitingList';
import HomepageAside from './HomepageAside';

const HomepageWithLayout = () => (
  <Layout showLogin isEvenSidebar asideContent={<HomepageAside />}>
    <Homepage />
  </Layout>
);
const CompanyDetailsWithLayout = () => (
  <Layout showLogo>
    <CompanyDetails />
  </Layout>
);
const ROWWaitingListWithLayout = () => (
  <Layout showLogo preventAnimations>
    <ROWWaitingList />
  </Layout>
);

const Signup = () => {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return <MobileWaitingList />;
  }

  return (
    <Routes>
      <Route index element={<HomepageWithLayout />} />
      <Route path="complete" element={<CompanyDetailsWithLayout />} />
      <Route path="coming-soon" element={<ROWWaitingListWithLayout />} />
    </Routes>
  );
};

export default Signup;
