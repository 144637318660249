import { Text, Stack } from '@veeqo/ui';
import TitleGroup from 'components/common/TitleGroup';
import Layout from 'components/layout/Layout';
import Divider from 'components/common/Divider';
import links from 'utils/externalLinks';
import React from 'react';
import LoginForm from 'components/Login/components/LoginForm';
import { LoginWithAmazon } from './LoginWithAmazon';
import LoginAside from './components/LoginAside';
import { Container, FormContainer, Link, Wrapper } from './styled';

const Login = () => (
  <Layout showLogo showSignup asideContent={<LoginAside />}>
    <Wrapper>
      <Container>
        <TitleGroup title="Welcome back!" subtitle="Continue to Veeqo" />
        <Stack spacing={8}>
          <LoginWithAmazon />
          <Divider />
          <FormContainer>
            <LoginForm />
          </FormContainer>
          <Link href={links.passwordReset}>
            <Text variant="link">Forgot password?</Text>
          </Link>
        </Stack>
      </Container>
    </Wrapper>
  </Layout>
);

export default Login;
