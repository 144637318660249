import * as Sentry from '@sentry/react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Signup from 'components/Signup/Signup';
import WaitingList from 'components/ContingencyWaitingList/WaitingList';
import ThirdPartyScripts from 'Marketing/ThirdPartyScripts';
import { LoginRoutes } from 'components/Login/routes';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const location = useLocation();

  return (
    <>
      <ThirdPartyScripts />
      <AnimatePresence mode="wait" initial={false}>
        <SentryRoutes key={location.pathname}>
          <Route path="/signin/*" element={<LoginRoutes />} />
          <Route path="/signup/waiting-list" element={<WaitingList />} />
          <Route path="/static_content/waiting-list.html" element={<WaitingList />} />
          <Route path="/signup/*" element={<Signup />} />
        </SentryRoutes>
      </AnimatePresence>
    </>
  );
}

export default App;
