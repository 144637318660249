import styled from 'styled-components';
import { Text, Tag, Stack, theme } from '@veeqo/ui';
import logo from '../../assets/veeqo-logo.svg';

type Direction = 'horizontal' | 'vertical';

interface VeeqoLogoProps {
  tag?: string;
  direction?: Direction;
}

const GreenTag = styled(Tag)<{ direction: Direction }>`
  background-color: ${theme.colors.secondary.green.light};
  ${({ direction }) => {
    if (direction === 'horizontal') return 'margin-top: -6px'; // adjust alignment with logo
  }};
`;

const Img = styled.img`
  width: 145px;
`;

const VeeqoLogo = ({ tag, direction = 'horizontal' }: VeeqoLogoProps) => {
  const spacing = direction === 'horizontal' ? 5 : 3;

  const Logo = <Img src={logo} alt="veeqo" />;

  if (tag) {
    return (
      <Stack direction={direction} alignY="center" spacing={spacing}>
        {Logo}
        <GreenTag direction={direction}>
          <Text variant="bodySmall">{tag}</Text>
        </GreenTag>
      </Stack>
    );
  }

  return Logo;
};

export default VeeqoLogo;
