import {
  addPartnerData,
  addUTMData,
  cTrackingListener,
  getMarketingConsent,
  identifySegmentEvent,
  trackSegmentEvent,
} from './utils/helpers';
import { Action, BasePayload, Event, Location } from './types';

/**
 * Track successful account creation and trigger associated metrics
 */
export const accountCreationTracking = (submitData: BasePayload) => {
  const {
    firstName,
    lastName,
    login,
    email,
    country,
    marketingConsent,
    companyName,
    addressLine1,
    city,
    region,
    postCode,
    phone,
    sellingPartnerId,
    sso,
    amazonOauthUid,
    monthlyOrderVolume,
  } = submitData;

  const baseData = {
    firstName,
    lastName,
    email,
    marketingConsent: getMarketingConsent(country, marketingConsent),
    company: {
      name: companyName,
    },
    addressLine1,
    city,
    region,
    postCode,
    country,
    phone,
    sellingPartnerId,
    get_address: true,
    sso,
    amazonOauthUid,
  };

  // Partnerstack
  const dataWithUTM = addUTMData(baseData);
  const dataWithPartner = addPartnerData(dataWithUTM);

  // Capterra
  cTrackingListener();

  // Segment
  identifySegmentEvent(dataWithPartner);
  trackSegmentEvent(Event.ACCOUNT_CREATED, {
    location: Location.SIGNUP_COMPANY_DETAILS,
    action: Action.CONNECT_STORE,
    login,
    firstName,
    lastName,
    email,
    phone,
    companyName,
    country,
    sso,
    amazonOauthUid,
    monthlyOrderVolume,
  });

  // Bing & Segment
  if (country === 'GB' || country === 'US') {
    window.uetq = window.uetq || [];
    window.uetq.push('event', '', { event_label: 'Form complete' });
  }
};
