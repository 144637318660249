import styled from 'styled-components';
import { Text, theme } from '@veeqo/ui';

interface DividerProps {
  text?: string;
}

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

const Line = styled.hr`
  width: 100%;
  border-top: 1px solid ${theme.colors.neutral.ink.lightest};
`;

const TextContent = styled(Text)`
  padding: 0 10px;
`;

const Divider = ({ text }: DividerProps) => (
  <Container>
    <Content>
      <Line />
      {text && (
        <>
          <TextContent variant="body">{text}</TextContent>
          <Line />
        </>
      )}
    </Content>
  </Container>
);

export default Divider;
