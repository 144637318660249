import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { Button, Stack, TextField, Checkbox } from '@veeqo/ui';
import formValidations from 'utils/validations';
import InputGroup from 'components/common/InputGroup';
import { mobileWaitingListSignupTracking } from 'Marketing/mobileSignupTracking';

export type MobileFormData = {
  firstName: string;
  lastName: string;
  email: string;
  marketingConsent: boolean;
};

interface WaitingFormProps {
  setMobileSuccess: (val: boolean) => void;
}

const Form = styled.form`
  button {
    width: 100%;
  }
  .stack--full-width {
    width: 100%;
  }
`;

const MarketingCheck = styled.div`
  * {
    font-size: 12px;
  }
`;

const MobileWaitingListForm = ({ setMobileSuccess }: WaitingFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MobileFormData>({
    defaultValues: { firstName: '', lastName: '', email: '', marketingConsent: false },
  });

  const onSubmit = handleSubmit((data) => {
    mobileWaitingListSignupTracking(data);
    setMobileSuccess(true);
  });

  return (
    <Form onSubmit={onSubmit}>
      <Stack spacing={6}>
        <InputGroup>
          <Controller
            name="firstName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                aria-label="first name"
                type="text"
                label="First name"
                error={errors.firstName && errors.firstName.message}
                placeholder="First name"
                className="fs-exclude"
              />
            )}
            rules={formValidations.firstName}
          />
        </InputGroup>
        <InputGroup>
          <Controller
            name="lastName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                aria-label="last name"
                type="text"
                label="Last name"
                error={errors.lastName && errors.lastName.message}
                placeholder="Last name"
                className="fs-exclude"
              />
            )}
            rules={formValidations.lastName}
          />
        </InputGroup>
        <InputGroup>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                aria-label="email"
                type="email"
                label="Email"
                error={errors.email && errors.email.message}
                placeholder="youremail@mail.com"
                className="fs-exclude"
              />
            )}
            rules={formValidations.email}
          />
        </InputGroup>
        <MarketingCheck>
          <Controller
            name="marketingConsent"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label="Sign up to our marketing list for helpful ecommerce content, product updates and help from our sales team."
                onChange={onChange}
                checked={value}
              />
            )}
          />
        </MarketingCheck>
        <Button type="submit" variant="primary">
          Let me know
        </Button>
      </Stack>
    </Form>
  );
};

export default MobileWaitingListForm;
