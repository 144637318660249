import { MobileFormData } from 'components/Mobile/MobileWaitingListForm';
import { addUTMData, identifySegmentEvent, trackSegmentEvent } from './utils/helpers';
import { Event, WaitingList } from './types';

export const mobileWaitingListSignupTracking = (formData: MobileFormData) => {
  const { firstName, lastName, email, marketingConsent } = formData;

  const baseData = {
    firstName,
    lastName,
    email,
    marketingConsent: marketingConsent ? new Date().toLocaleString('en-GB') : false,
    signup_waiting_list: WaitingList.MOBILE,
    company: {
      name: email,
    },
  };

  const dataWithUTM = addUTMData(baseData);

  identifySegmentEvent(dataWithUTM);
  trackSegmentEvent(Event.MOBILE_WAITING_LIST_SUBSCRIPTION, { firstName, lastName, email });
};
