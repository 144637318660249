import { GlobalFormData } from 'components/ContingencyWaitingList/WaitingListForm';
import { addUTMData, identifySegmentEvent, trackSegmentEvent } from './utils/helpers';
import { Event, WaitingList } from './types';

export const contingencyWaitingListTracking = (formData: GlobalFormData) => {
  const { firstName, lastName, email, marketingConsent } = formData;

  const baseData = {
    firstName,
    lastName,
    email,
    marketingConsent: marketingConsent ? new Date().toLocaleString('en-GB') : false,
    signup_waiting_list: WaitingList.CONTINGENCY,
    company: {
      name: email,
    },
  };

  const dataWithUTM = addUTMData(baseData);

  identifySegmentEvent(dataWithUTM);
  trackSegmentEvent(Event.CONTINGENCY_WAITING_LIST_SUBSCRIPTION, { firstName, lastName, email });
};
