/* eslint-disable @typescript-eslint/naming-convention */
import { fetchWithCsrf } from 'utils/fetchWithCsrf';
import * as Sentry from '@sentry/react';
import {
  backendHost,
  backendSignupUrl,
  backendLoginUrl,
  apiHeaders,
  backendLoginVerifyUrl,
} from 'utils/constants';
import { LoginData, SignupData } from 'context/types';

export type SignupResponse = {
  redirect_to?: string;
  error_messages?: string[];
};

export type LoginResponse = {
  redirect_to?: string;
  error?: boolean;
  isVerificationRequired?: boolean;
};

const decamelizedSignupData = (data: SignupData) => {
  const {
    country,
    email,
    login,
    password,
    companyName,
    addressLine1,
    city,
    region,
    postCode,
    phone,
    marketingConsent,
    termsAndConditions,
    amazonOauthAccessToken,
    amazonOauthUid,
    timezone,
  } = data;
  const result = {
    login,
    email,
    city,
    region,
    timezone,
    post_code: postCode,
    address_line_1: addressLine1,
    company_name: companyName,
    country,
    phone,
    terms_and_conditions: termsAndConditions,
    marketing_consent: marketingConsent,
  };
  // TODO: return url submit
  if (amazonOauthUid?.length && amazonOauthAccessToken?.length) {
    return {
      ...result,
      amazon_oauth_uid: amazonOauthUid,
      amazon_oauth_access_token: amazonOauthAccessToken,
    };
  }
  return { ...result, password };
};

export const submitSignupData = async (submitData: SignupData) => {
  try {
    const response = await fetchWithCsrf(`${backendHost}${backendSignupUrl}`, {
      method: 'POST',
      mode: backendHost?.length ? 'cors' : 'same-origin',
      headers: apiHeaders,
      body: JSON.stringify({ signup: decamelizedSignupData(submitData) }),
    });
    const { redirect_to, error_messages }: SignupResponse = await response.json();

    if (redirect_to) {
      // expected location has sellercentral url to start or continue the channel flow
      global.location.assign(redirect_to);
    }

    if (error_messages) {
      return { error_messages };
    }
  } catch (e) {
    return { error_messages: [] };
  }
};

export const submitLogin = async (loginData: LoginData) => {
  try {
    const response = await fetchWithCsrf(`${backendHost}${backendLoginUrl}`, {
      method: 'POST',
      mode: backendHost?.length ? 'cors' : 'same-origin',
      headers: apiHeaders,
      body: JSON.stringify(loginData),
    });
    const { redirect_to, authentication_required } = await response.json();
    return { redirect_to, isVerificationRequired: authentication_required };
  } catch (e) {
    // TODO => improve logic on BE as the current response is empty and raises a syntax error.
    // Differentiate between user error (password || email) & server error
    return { error: true };
  }
};

export const verifyCode = async (data: { code: string }) => {
  try {
    const response = await fetchWithCsrf(`${backendHost}${backendLoginVerifyUrl}`, {
      method: 'POST',
      mode: backendHost?.length ? 'cors' : 'same-origin',
      headers: apiHeaders,
      body: JSON.stringify(data),
    });
    const result: LoginResponse = await response.json();

    if (result.redirect_to) {
      global.location.href = result.redirect_to;
    } else {
      Sentry.captureMessage('No redirect_to when verifying code');
    }
  } catch (e) {
    return { error: true };
  }
};
