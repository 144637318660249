import { backendHost } from 'utils/constants';

const links = {
  veeqoHelp: 'https://help.veeqo.com/',
  veeqoPrivacy: 'https://www.veeqo.com/privacy',
  veeqoTerms: 'https://www.veeqo.com/terms-and-conditions',
  veeqoTermsUK: 'https://www.veeqo.com/gb/terms-and-conditions',
  fedexTerms: 'https://www.fedex.com/en-us/terms-of-use.html',
  upsTerms: 'https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page',
  uspsTerms: 'https://pe.usps.com/',
  dhlTerms:
    'https://www.dhl.com/us-en/home/ecommerce-solutions/business-help-center/terms-and-conditions.html',
  passwordReset: `${backendHost}/password_reset`,
};

export default links;
