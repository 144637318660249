import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import {
  LocationClient,
  SearchPlaceIndexForSuggestionsCommand,
  SearchPlaceIndexForTextCommand,
} from '@aws-sdk/client-location';
import { awsIdentityPoolId, awsRegion, awsLocationIndexName } from 'utils/constants';
import states from 'utils/states.json';

const awsLocationClient = new LocationClient({
  region: awsRegion,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: awsRegion }),
    identityPoolId: awsIdentityPoolId,
  }),
});

// save size now, full list https://github.com/vtex/country-iso-2-to-3/blob/master/index.js
const countryIso2to3 = { US: 'USA', GB: 'GBR' };
type CountryKey = keyof typeof countryIso2to3;
// save size now, full list https://github.com/vtex/country-iso-3-to-2/blob/master/index.js
// const countryIso3to2 = { "USA": "US", "GBR": "GB"};

// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-location/classes/searchplaceindexforsuggestionscommand.html
export const fetchSuggestions = async (text: string, country?: string): Promise<string[]> => {
  const input = {
    IndexName: awsLocationIndexName,
    Text: text,
    MaxResults: 5,
  };
  const filterInput =
    country && ['US', 'GB'].includes(country) && countryIso2to3[country as CountryKey]
      ? { FilterCountries: [countryIso2to3[country as CountryKey]] }
      : {};
  const command = new SearchPlaceIndexForSuggestionsCommand({ ...input, ...filterInput });
  const response = await awsLocationClient.send(command);
  return response.Results ? response.Results.map((r) => r.Text as string) : [];
};

type Location = Partial<{
  country: string;
  region: string;
  city: string;
  addressLine1: string;
  postCode: string;
}>;

const stateByLabel = Object.fromEntries(states.map((state) => [state.label, state.value]));

const normalizePostCode = (country: string | undefined, postCode: string | undefined) => {
  if (country !== 'USA') return postCode;

  return postCode?.replaceAll(' ', '-');
};

export const fetchLocation = async (text: string, country?: string): Promise<Location | null> => {
  const input = {
    IndexName: awsLocationIndexName,
    Text: text,
    MaxResults: 1,
  };
  const filterInput =
    country && ['US', 'GB'].includes(country) && countryIso2to3[country as CountryKey]
      ? { FilterCountries: [countryIso2to3[country as CountryKey]] }
      : {};
  const command = new SearchPlaceIndexForTextCommand({ ...input, ...filterInput });
  const response = await awsLocationClient.send(command);
  const result = response.Results && response.Results[0];
  if (!result) return null;
  const place = result.Place;
  if (!place) return null;

  const { Country, Label, Region, PostalCode, Municipality, AddressNumber, Street } = place;
  const regionCode = Region ? stateByLabel[Region] : Region;
  const addressLine1 =
    AddressNumber?.length || Street?.length
      ? [AddressNumber, Street].filter((t) => t?.length).join(' ')
      : Label;
  const location = {
    country: Country,
    region: regionCode,
    city: Municipality,
    postCode: normalizePostCode(Country, PostalCode),
    addressLine1,
  };
  return location;
};
