import { Stack } from '@veeqo/ui';
import TitleGroup from 'components/common/TitleGroup';
import Layout from 'components/layout/Layout';
import LoginVerificationForm from './components/LoginVerificationForm';
import LoginAside from './components/LoginAside';
import { Container, FormContainer, Wrapper } from './styled';

export const LoginVerification = () => (
  <Layout showLogo showSignup asideContent={<LoginAside />}>
    <Wrapper>
      <Container>
        <TitleGroup
          title="Verify your identity"
          subtitle="An email with a single-use code was just sent to your email address. If you have set up an authenticator app for Veeqo, use the code to verify your identity."
        />
        <Stack spacing={8}>
          <FormContainer>
            <LoginVerificationForm />
          </FormContainer>
        </Stack>
      </Container>
    </Wrapper>
  </Layout>
);
