import { getUTMData } from './cookies';
import {
  Event,
  IdentifyIntegrations,
  IdentifyTraits,
  IdentifyTraitsBase,
  IdentifyTraitsWithUTMs,
  TrackProperties,
} from '../types';

export const getUserCurrency = (country: string) => (country === 'US' ? 'USD' : 'GBP');

export const cTrackingListener = () => {
  const capterraVkey = '1150b5fd130ced0149065a26555d213a';
  const capterraVid = '2096571';
  const ct = document.createElement('img');
  ct.src = `https://ct.capterra.com/capterra_tracker.gif?vid=${capterraVid}&vkey=${capterraVkey}`;
  document.body.appendChild(ct);
};

/**
 * Wrapper for the Segment identify call
 * @param traits Will be passed as traits
 * @param integrations Will be passed to the options object
 */
export function identifySegmentEvent(
  traits: IdentifyTraits,
  integrations: IdentifyIntegrations = {},
) {
  if (typeof window.analytics !== 'undefined') {
    /**
     * Anatomy: analytics.identify([userId], [traits], [options], [callback]);
     * - userId, optional, string (can be ommited to use an anonymous id)
     * - traits, optional, object
     * - options, optional, object (if passing options with no traits, pass {} for traits)
     * - callback, optional, function
     * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/ajs-classic/#identify
     */
    window.analytics.identify(traits, {
      integrations: {
        'Salesforce (Actions)': integrations.salesforce ?? true,
      },
    });
  }
}

/**
 * Wrapper for the Segment event tracking call
 * @param event Will be passed as the event key
 * @param properties Will be passed to the properties object
 */
export const trackSegmentEvent = (
  event: Event,
  properties: TrackProperties = {},
  integrations: IdentifyIntegrations = {},
) => {
  if (typeof window.analytics !== 'undefined') {
    window.analytics.track(event, properties, {
      integrations: {
        'Salesforce (Actions)': integrations.salesforce ?? true,
      },
    });
  }
};

export const addUTMData = (segmentData: IdentifyTraitsBase) => {
  const UTMData = getUTMData(); // exports needed to allow mocking
  return { ...segmentData, ...UTMData };
};

export const addPartnerData = (segmentData: IdentifyTraitsWithUTMs) => {
  const partnerData = {
    lead_type: 'Channel',
    lead_source_origin: 'Product | Free Trial',
    partner_key: window.growsumo.data.partner_key,
    partner_email_address: 'partners@veeqo.com',
  };
  return { ...segmentData, ...partnerData };
};

export const getMarketingConsent = (
  country: string | undefined,
  hasConsent: boolean | undefined,
) => {
  const currentDate = new Date().toLocaleString('en-GB');

  if (country === 'US') return currentDate;
  return hasConsent ? currentDate : false;
};
