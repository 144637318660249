import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { theme, Button, Stack, Text } from '@veeqo/ui';
import VeeqoLogo from 'components/common/VeeqoLogo';
import TitleGroup from 'components/common/TitleGroup';
import UserDetailsForm from 'components/Signup/UserDetailsForm';
import { useAppContext } from 'context/Context';
import Divider from 'components/common/Divider';
import { AmazonButton } from 'components/common/AmazonButton';
import useDefaultParamsCountry from 'utils/useDefaultParamsCountry';
import { amazonSso } from 'utils/amazonSso';
import { SignupData } from 'context/types';
import { signupInitiatedSSOTracking } from 'Marketing/signupInitiatedTracking';
import { EmailIcon } from './icons/EmailIcon';

interface EmailButtonProps {
  isVisible: boolean;
  onClick: () => void;
}

interface UserFormProps {
  isVisible: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 420px;
  margin-top: ${theme.sizes[20]};
`;

const ButtonsGroup = styled(Stack)`
  width: 100%;
`;

const AmazonGroup = styled(Stack)`
  width: 100%;
`;

const EmailBtn = styled(Button)`
  // Avoid flash of content during the animation
  &:active {
    border-color: none;
    box-shadow: none;
  }
`;

const FormContainer = styled.div`
  width: 100%;
`;

const EmailButton = ({ isVisible, onClick }: EmailButtonProps) => {
  if (!isVisible) return null;

  return (
    <EmailBtn
      icon={<EmailIcon />}
      style={{ width: '100%' }}
      onClick={onClick}
      aria-label="continue with email"
    >
      Continue with Email
    </EmailBtn>
  );
};

const UserForm = ({ isVisible }: UserFormProps) => {
  if (!isVisible) return null;

  return (
    <AnimatePresence initial>
      <motion.div
        style={{ width: '100%', overflow: 'hidden' }}
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: 'auto' }}
        transition={{ duration: 0.3 }}
      >
        <FormContainer>
          <UserDetailsForm />
        </FormContainer>
      </motion.div>
    </AnimatePresence>
  );
};

const Homepage = () => {
  const navigate = useNavigate();
  const {
    actions,
    state: { signupData },
  } = useAppContext();
  const country = useDefaultParamsCountry();
  const [showUserDetails, setShowUserDetails] = useState(false);

  const handleClickAmazon = () => {
    signupInitiatedSSOTracking(country);

    if (global?.amazon?.Login) {
      amazonSso(signupData, (data: SignupData) => {
        // TODO: try login here using just email and amazon token?
        actions.setSignupData({
          ...data,
          termsAndConditions: true,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        navigate('complete');
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('No amazon.Login loaded');
    }
  };

  const handleClickEmail = () => {
    setShowUserDetails(true);
  };
  const subtitleCopy =
    country === 'US'
      ? 'Get started with Veeqo for free.'
      : 'A couple of steps before getting started.';

  return (
    <Wrapper>
      <Container>
        <VeeqoLogo />
        <TitleGroup title="Create your Veeqo Account" subtitle={subtitleCopy} />
        <Stack spacing={6}>
          <ButtonsGroup spacing={8}>
            <AmazonGroup alignX="center">
              <AmazonButton
                text="Continue with Amazon"
                style={{ width: '100%' }}
                onClick={handleClickAmazon}
              />
              <Text variant="hintText">Make sure to use your Amazon Seller Central account.</Text>
            </AmazonGroup>
            <Divider text="OR" />
            <EmailButton isVisible={!showUserDetails} onClick={handleClickEmail} />
          </ButtonsGroup>
          <UserForm isVisible={showUserDetails} />
        </Stack>
      </Container>
    </Wrapper>
  );
};

export default Homepage;
