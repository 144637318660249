import React from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { theme, Button, Stack, TextField, Checkbox } from '@veeqo/ui';
import InputGroup from 'components/common/InputGroup';
import formValidations from 'utils/validations';
import TermsConditions from 'components/Terms/TermsConditions';
import { contingencyWaitingListTracking } from 'Marketing/contingencyWaitingListTracking';

interface SignupWaitingListFormProps {
  setSuccess: (email: string) => void;
}

export type GlobalFormData = {
  firstName: string;
  lastName: string;
  email: string;
  marketingConsent: boolean;
};

const FormContainer = styled.div`
  margin-top: ${theme.sizes[11]};
`;

const Form = styled.form`
  button {
    width: 100%;
  }

  .stack--full-width {
    width: 100%;
  }
`;

const SignupWaitingListForm = ({ setSuccess }: SignupWaitingListFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<GlobalFormData>({
    defaultValues: { firstName: '', lastName: '', email: '', marketingConsent: false },
  });

  const onSubmit = handleSubmit((formData) => {
    contingencyWaitingListTracking(formData);
    setSuccess(formData.email);
  });

  return (
    <FormContainer>
      <Form onSubmit={onSubmit}>
        <Stack spacing={6}>
          <InputGroup>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  aria-label="first name"
                  type="text"
                  label="First name"
                  error={errors.firstName && errors.firstName.message}
                  placeholder="First name"
                  className="fs-exclude"
                />
              )}
              rules={formValidations.firstName}
            />
          </InputGroup>
          <InputGroup>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  aria-label="last name"
                  type="text"
                  label="Last name"
                  error={errors.lastName && errors.lastName.message}
                  placeholder="Last name"
                  className="fs-exclude"
                />
              )}
              rules={formValidations.lastName}
            />
          </InputGroup>
          <InputGroup>
            <Controller
              name="email"
              control={control}
              rules={formValidations.email}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  aria-label="email"
                  type="email"
                  label="Email"
                  error={errors.email && errors.email.message}
                  placeholder="youremail@mail.com"
                  className="fs-exclude"
                />
              )}
            />
          </InputGroup>
          <Controller
            name="marketingConsent"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label="Sign up to our marketing list for helpful ecommerce content, product updates and help from our sales team."
                onChange={onChange}
                checked={value}
              />
            )}
          />
          <Button type="submit" variant="primary">
            Let me know
          </Button>
        </Stack>
      </Form>
      <TermsConditions waitingList />
    </FormContainer>
  );
};

export default SignupWaitingListForm;
